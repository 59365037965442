type Store = {
  id: number;
  storeName: string;
};

export const StoreDetails: Store[] = [
  { id: 1, storeName: "La Parrilla (Marietta Square)" },
  { id: 2, storeName: "La Parrilla (Dallas Hwy)" },
  { id: 3, storeName: "La Parrilla (Flowery Branch)" },
  { id: 4, storeName: "La Parrilla (Powder Springs)" },
  { id: 5, storeName: "La Parrilla (Fayetteville)" },
  { id: 6, storeName: "La Parrilla (Alpharetta)" },
  { id: 7, storeName: "La Parrilla (Woodstock)" },
  { id: 9, storeName: "La Parrilla (Acworth)" },
  { id: 10, storeName: "La Parrilla (Newnan)" },
  { id: 11, storeName: "La Parrilla (McDonough)" },
  { id: 12, storeName: "La Parrilla (Howell Mill)" },
  { id: 13, storeName: "La Parrilla (Dothan)" },
  { id: 14, storeName: "La Parrilla (Norcross)" },
  { id: 15, storeName: "La Parrilla (Canton)" },
  { id: 16, storeName: "La Parrilla (Macon)" },
  { id: 17, storeName: "La Parrilla (Greenville)" },
  { id: 18, storeName: "La Parrilla (Toco Hills)" },
  { id: 19, storeName: "La Parrilla (Savannah)" },
  { id: 20, storeName: "La Parrilla (Griffin)" },
  { id: 23, storeName: "La Parrilla (Sandy Springs)" },
  { id: 24, storeName: "La Cosecha Pooler GA LLC" },
  { id: 25, storeName: "La Cosecha Athens LLC" },
  { id: 26, storeName: "La Cosecha Sharpsburg LLC" },
  { id: 500, storeName: "Casi Cielo" },
];
